<template>
  <div class="room-batch">
    <app-header title="房间批量添加" :isShowBack="true"></app-header>

    <div class="row-title">基础信息</div>
    <app-form :opt="baseOpt" entry="roomDatchBaseForm"></app-form>

    <div v-show="isShowLong">
      <div class="row-title">长租房间信息</div>
      <app-form :opt="longOpt" entry="roomDatchLongForm"></app-form>
      <div class="row-line"></div>
      <div class="list-box">
        <div class="list-item">
          <div class="list-cols">
            <label>房间数</label>
            <el-input
              v-model="roomNumLong"
              v-on:input="longFun"
              placeholder="请输入房间数"
              maxlength="3"
              onkeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
            ></el-input>
            <span>输入所需的房间数自动生成下面列数</span>
          </div>
        </div>
        <div class="list-item" v-for="(item, index) in roomList" :key="index">
          <div class="list-idx">{{index+1}}.</div>
          <div class="list-col">
            <label>房号</label>
            <el-input v-model="item.roomNo" placeholder="请输入房号" maxlength="24"></el-input>
          </div>
          <div class="list-col" v-show="isLongDiscount">
            <label>原价(元/月)</label>
            <el-input
              v-model="item.rentalOriginalFee"
              @keyup.native="keyUpChange(index,'rentalOriginalFee')"
              :placeholder="index==0?'请输入原价':'同上'"
            ></el-input>
          </div>
          <div class="list-col">
            <label>{{isLongDiscount?'折后价(元/月)':'房价'}}</label>
            <el-input
              v-model="item.rentalFee"
              @keyup.native="keyUpChange(index,'rentalFee')"
              :placeholder="index==0?'请输入折后价':'同上'"
            ></el-input>
          </div>
          <div class="list-col">
            <label>押金</label>
            <el-input
              v-model="item.deposit"
              @keyup.native="keyUpChange(index,'deposit')"
              :placeholder="index==0?'请输入押金':'同上'"
            ></el-input>
          </div>
          <div class="list-col">
            <label>物业费</label>
            <el-input
              v-model="item.propertyFee"
              @keyup.native="keyUpChange(index,'propertyFee')"
              :placeholder="index==0?'请输入物业费':'同上'"
            ></el-input>
          </div>
          <div class="list-buts">
            <el-button class="list-but" type="primary" icon="el-icon-plus" @click="addItem(index)"></el-button>
            <el-button class="list-but" type="danger" icon="el-icon-minus" @click="delItem(index)"></el-button>
          </div>
        </div>
      </div>
    </div>

    <div v-show="isShowShort">
      <div class="row-title">短租房间信息</div>
      <app-form :opt="shortOpt" entry="roomDatchShortForm"></app-form>
      <div class="row-line"></div>
      <div class="list-box">
        <div class="list-item">
          <div class="list-cols">
            <label>房间数</label>
            <el-input
              v-model="roomNumLong"
              v-on:input="longFun"
              placeholder="请输入房间数"
              maxlength="3"
              onkeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
            ></el-input>
            <span>输入所需的房间数自动生成下面列数</span>
          </div>
        </div>
        <div class="list-item" v-for="(item, index) in roomList" :key="index">
          <div class="list-idx">{{index+1}}.</div>
          <div class="list-col">
            <label>房号</label>
            <el-input v-model="item.roomNo" placeholder="请输入房号" maxlength="24"></el-input>
          </div>
          <div class="list-col" v-show="isShortDiscount">
            <label>原价(元/天)</label>
            <el-input
              v-model="item.shortOriginalFee"
              @keyup.native="keyUpChange(index,'shortOriginalFee')"
              :placeholder="index==0?'请输入原价':'同上'"
            ></el-input>
          </div>
          <div class="list-col">
            <label>{{isShortDiscount?'折后价(元/天)':'房价'}}</label>
            <el-input
              v-model="item.shortRentFee"
              @keyup.native="keyUpChange(index,'shortRentFee')"
              :placeholder="index==0?'请输入折后价':'同上'"
            ></el-input>
          </div>
          <div class="list-col">
            <label>押金</label>
            <el-input
              v-model="item.shortDeposit"
              @keyup.native="keyUpChange(index,'shortDeposit')"
              :placeholder="index==0?'请输入押金':'同上'"
            ></el-input>
          </div>
          <div class="list-buts">
            <el-button class="list-but" type="primary" icon="el-icon-plus" @click="addItem(index)"></el-button>
            <el-button class="list-but" type="danger" icon="el-icon-minus" @click="delItem(index)"></el-button>
          </div>
        </div>
      </div>
    </div>

    <div v-show="isShowClock">
      <div class="row-title">钟点房间信息</div>
      <div class="row-line"></div>
      <div class="list-box">
        <div class="list-item">
          <div class="list-cols">
            <label>房间数</label>
            <el-input
              v-model="roomNumLong"
              v-on:input="longFun"
              placeholder="请输入房间数"
              maxlength="3"
              onkeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
            ></el-input>
            <span>输入所需的房间数自动生成下面列数</span>
          </div>
        </div>
        <div class="list-item" v-for="(item, index) in roomList" :key="index">
          <div class="list-idx">{{index+1}}.</div>
          <div class="list-col">
            <label>房号</label>
            <el-input v-model="item.roomNo" placeholder="请输入房号" maxlength="24"></el-input>
          </div>
          <div class="list-col">
            <label>房价</label>
            <el-input
              v-model="item.rentalFee"
              @keyup.native="keyUpChange(index,'rentalFee')"
              :placeholder="index==0?'请输入房价':'同上'"
            ></el-input>
          </div>
          <div class="list-col">
            <label>押金</label>
            <el-input
              v-model="item.deposit"
              @keyup.native="keyUpChange(index,'deposit')"
              :placeholder="index==0?'请输入押金':'同上'"
            ></el-input>
          </div>
          <div class="list-buts">
            <el-button class="list-but" type="primary" icon="el-icon-plus" @click="addItem(index)"></el-button>
            <el-button class="list-but" type="danger" icon="el-icon-minus" @click="delItem(index)"></el-button>
          </div>
        </div>
      </div>
    </div>

    <footer class="action-but">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </footer>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      baseOpt: {
        form: [
          {
            label: "房源",
            key: "apartmentId",
            type: "select",
            isCol33: true,
            opt: {
              list: [],
              change(data) {
                // 赋值楼栋数
                let list = _this.baseOpt.form[0].opt.list;
                _this.baseOpt.form[3].opt.list = [];
                list.forEach(item => {
                  if (item.value == data) {
                    if (item.buildingList && item.buildingList.length > 0) {
                        item.buildingList.forEach(it => {
                          _this.baseOpt.form[3].opt.list.push({
                            label: it.buildName,
                            value: Number(it.buildCode)
                          })
                        })
                      }else {
                        for (var i = 0; i < item.buildings; i++) {
                          _this.baseOpt.form[3].opt.list.push({
                            label: i + 1 + "栋",
                            value: i + 1
                          });
                        }
                      }
                      _this.buildingsList = _this.baseOpt.form[3].opt.list
                  }
                });
                _this.getTypeIdList(data);
              }
            },
            rules: [{ required: true }]
          },
          {
            label: "房型",
            key: "roomType",
            type: "select",
            isCol33: true,
            opt: {
              list: [],
              parent: "apartmentId"
            },
            rules: [{ required: true }]
          },
          {
            label: "楼层",
            key: "floor",
            type: "number",
            isCol33: true,
            isWidth100: true,
            rules: [{ required: true }]
          },
          {
            label: "楼栋名称",
            key: "buildingNo",
            type: "select",
            isCol33: true,
            opt: {
              list: [],
              parent: "apartmentId"
            },
            rules: [{ required: true }]
          },
          {
            label: "租赁模式",
            key: "rentalStyle",
            type: "select",
            opt: {
              list: [
                {
                  label: "长租",
                  value: "0"
                },
                {
                  label: "短租",
                  value: "1"
                },
                {
                  label: "长短租",
                  value: "2"
                },
                {
                  label: "钟点房",
                  value: "3"
                }
              ],
              change(val) {
                switch (val) {
                  case "0":
                    _this.isShowLong = true;
                    _this.isShowShort = false;
                    _this.isShowClock = false;
                    break;
                  case "1":
                    _this.isShowLong = false;
                    _this.isShowShort = true;
                    _this.isShowClock = false;
                    break;
                  case "2":
                    _this.isShowLong = true;
                    _this.isShowShort = true;
                    _this.isShowClock = false;
                    break;
                  case "3":
                    _this.isShowLong = false;
                    _this.isShowShort = false;
                    _this.isShowClock = true;
                    break;
                  default:
                    break;
                }
              }
            },
            isCol33: true,
            rules: [{ required: true }]
          },
          {
            label: "房间图片",
            key: "roomPics",
            type: "upload-img-form",
            opt: {
              url: _this.UPLOAD_URL + "/user-service/weChat/uploadPic",
              urlKey: "picPath",
              limit: 6,
              upload(data) {
                console.log(data);
                return true;
              },
              remove(file, fileList) {
                console.log(file, fileList);
                return true;
              }
            }
          },
          {
            label: "房间简介",
            key: "description",
            type: "textarea"
          },
          {
            label: "是否发布到房源页",
            key: "isShow",
            type: "select",
            value: "1",
            opt: {
              list: [
                {
                  label: "是",
                  value: "0"
                },
                {
                  label: "否",
                  value: "1"
                }
              ]
            },
            rules: [{ required: true }],
          }
        ]
      },
      longOpt: {
        form: [
          {
            label: "租赁合同类型",
            key: "contractType",
            type: "select",
            value: 1,
            opt: {
              list: [
                {
                  label: "线下纸质合同",
                  value: 1
                },
                {
                  label: "CA电子合同",
                  value: 2
                }
              ]
            },
            isCol50: true,
            rules: [{ required: true }]
          },
          {
            label: "交租日期",
            key: "payDate",
            isCol50: true,
            type: "select",
            opt: {
              list: [
                {
                  value: 4,
                  label: "每月4号"
                },
                {
                  value: 5,
                  label: "每月5号"
                },
                {
                  value: 6,
                  label: "每月6号"
                },
                {
                  value: 7,
                  label: "每月7号"
                },
                {
                  value: 8,
                  label: "每月8号"
                },
                {
                  value: 9,
                  label: "每月9号"
                },
                {
                  value: 10,
                  label: "每月10号"
                }
              ]
            },
            rules: [{ required: true }]
          },
          {
            label: "允许逾期天数",
            key: "expiredDay",
            isCol50: true,
            type: "select",
            value: 3,
            opt: {
              list: [
                {
                  value: 0,
                  label: "当天"
                },
                {
                  value: 1,
                  label: "1天"
                },
                {
                  value: 2,
                  label: "2天"
                },
                {
                  value: 3,
                  label: "3天"
                },
                {
                  value: 4,
                  label: "4天"
                },
                {
                  value: 5,
                  label: "5天"
                },
                {
                  value: 6,
                  label: "6天"
                },
                {
                  value: 7,
                  label: "7天"
                },
                {
                  value: 8,
                  label: "8天"
                },
                {
                  value: 9,
                  label: "9天"
                },
                {
                  value: 10,
                  label: "10天"
                },
                {
                  value: 11,
                  label: "11天"
                },
                {
                  value: 12,
                  label: "12天"
                },
                {
                  value: 13,
                  label: "13天"
                },
                {
                  value: 14,
                  label: "14天"
                },
                {
                  value: 15,
                  label: "15天"
                }
              ]
            },
            rules: [{ required: true }]
          },
          {
            label: "逾期当天截止至",
            key: "expiredTime",
            isCol50: true,
            type: "select",
            value: 12,
            opt: {
              list: [
                {
                  value: 0,
                  label: "00:00"
                },
                {
                  value: 1,
                  label: "01:00"
                },
                {
                  value: 2,
                  label: "02:00"
                },
                {
                  value: 3,
                  label: "03:00"
                },
                {
                  value: 4,
                  label: "04:00"
                },
                {
                  value: 5,
                  label: "05:00"
                },
                {
                  value: 6,
                  label: "06:00"
                },
                {
                  value: 7,
                  label: "07:00"
                },
                {
                  value: 8,
                  label: "08:00"
                },
                {
                  value: 9,
                  label: "09:00"
                },
                {
                  value: 10,
                  label: "10:00"
                },
                {
                  value: 11,
                  label: "11:00"
                },
                {
                  value: 12,
                  label: "12:00"
                },
                {
                  value: 13,
                  label: "13:00"
                },
                {
                  value: 14,
                  label: "14:00"
                },
                {
                  value: 15,
                  label: "15:00"
                },
                {
                  value: 16,
                  label: "16:00"
                },
                {
                  value: 17,
                  label: "17:00"
                },
                {
                  value: 18,
                  label: "18:00"
                },
                {
                  value: 19,
                  label: "19:00"
                },
                {
                  value: 20,
                  label: "20:00"
                },
                {
                  value: 21,
                  label: "21:00"
                },
                {
                  value: 22,
                  label: "22:00"
                },
                {
                  value: 23,
                  label: "23:00"
                },
                {
                  value: 24,
                  label: "24:00"
                }
              ]
            },
            rules: [{ required: true }]
          },
          {
            label: "是否打折",
            key: "rentalDiscountFlag",
            only: "other",
            value: "0",
            type: "select",
            opt: {
              list: [
                {
                  label: "否",
                  value: "0"
                },
                {
                  label: "是",
                  value: "1"
                }
              ],
              change(val) {
                console.log(val);
                _this.isLongDiscount = val == "1";
              }
            },
            isCol33: true,
            rules: [{ required: true }]
          },
          {
            label: "打折时段",
            key: "rentalDiscountType",
            type: "select",
            filters: [
              {
                key: "rentalDiscountFlag",
                value: "1"
              }
            ],
            value: "0",
            opt: {
              list: [
                {
                  label: "持续打折",
                  value: "0"
                },
                {
                  label: "设置时段",
                  value: "1"
                }
              ]
            },
            isCol33: true,
            rules: [{ required: true }]
          },
          {
            label: "扣价时段",
            key: "rentalDiscountTimeRange",
            type: "daterange",
            filters: [
              {
                key: "rentalDiscountFlag",
                value: "1"
              },
              {
                key: "rentalDiscountType",
                value: "1"
              }
            ],
            rules: [{ required: true }]
          },
          {
            label: "电费",
            key: "electricityFees",
            value: "4",
            type: "select",
            // filters: {
            //   key: "rentalStyle",
            //   value: "0"
            // },
            opt: {
              list: [
                {
                  label: "自理",
                  value: "4"
                },
                {
                  label: "固定单价",
                  value: "2"
                }
                // {
                //   label: "固定金额",
                //   value: "3"
                // },
                // {
                //   label: "阶梯收费",
                //   value: "1"
                // },
              ]
            },
            isCol50: true,
            rules: [{ required: true }]
          },
          {
            subLabel: "",
            type: "TitleName",
            filters: [
              {
                key: "electricityFees",
                value: "4"
              }
              // {
              //   key: "rentalStyle",
              //   value: "0"
              // }
            ],
            isCol50: true,
            rules: [{ required: true }]
          },
          {
            label: "价格（元/度）",
            key: "electricityFeesPrice",
            filters: [
              {
                key: "electricityFees",
                value: "2"
              }
              // {
              //   key: "rentalStyle",
              //   value: "0"
              // }
            ],
            isCol50: true,
            rules: [{ required: true }],
            opt: {
              keyupNative: true
            }
          },
          {
            label: "冷水",
            key: "coldWater",
            value: "4",
            type: "select",
            // filters: {
            //   key: "rentalStyle",
            //   value: "0"
            // },
            //1：阶梯收费 2：固定价格   3：固定金额  4：自理
            opt: {
              list: [
                {
                  label: "自理",
                  value: "4"
                },
                {
                  label: "固定单价",
                  value: "2"
                },
                {
                  label: "固定金额",
                  value: "3"
                }
                // {
                //   label: "阶梯收费",
                //   value: "1"
                // },
              ]
            },
            isCol50: true,
            rules: [{ required: true }]
          },
          {
            subLabel: "",
            type: "TitleName",
            filters: [
              {
                key: "coldWater",
                value: "4"
              }
              // {
              //   key: "rentalStyle",
              //   value: "0"
              // }
            ],
            isCol50: true,
            rules: [{ required: true }]
          },
          {
            label: "价格（元/吨）",
            key: "coldWaterPrice",
            filters: [
              {
                key: "coldWater",
                value: "2"
              }
              // {
              //   key: "rentalStyle",
              //   value: "0"
              // }
            ],
            isCol50: true,
            rules: [{ required: true }],
            opt: {
              keyupNative: true
            }
          },
          {
            label: "价格（元/月）",
            key: "coldWaterPrice2",
            filters: [
              {
                key: "coldWater",
                value: "3"
              }
              // {
              //   key: "rentalStyle",
              //   value: "0"
              // }
            ],
            isCol50: true,
            rules: [{ required: true }],
            opt: {
              keyupNative: true
            }
          },
          {
            label: "热水",
            key: "hotWater",
            value: "4",
            type: "select",
            // filters: {
            //   key: "rentalStyle",
            //   value: "0"
            // },
            opt: {
              list: [
                {
                  label: "自理",
                  value: "4"
                },
                {
                  label: "固定单价",
                  value: "2"
                },
                {
                  label: "固定金额",
                  value: "3"
                }
                // {
                //   label: "阶梯收费",
                //   value: "1"
                // },
              ]
            },
            isCol50: true,
            rules: [{ required: true }]
          },
          {
            subLabel: "",
            type: "TitleName",
            filters: [
              {
                key: "hotWater",
                value: "4"
              }
              // {
              //   key: "rentalStyle",
              //   value: "0"
              // }
            ],
            isCol50: true,
            rules: [{ required: true }]
          },
          {
            label: "价格（元/吨）",
            key: "hotWaterPrice",
            filters: [
              {
                key: "hotWater",
                value: "2"
              }
              // {
              //   key: "rentalStyle",
              //   value: "0"
              // }
            ],
            isCol50: true,
            rules: [{ required: true }],
            opt: {
              keyupNative: true
            }
          },
          {
            label: "价格（元/月）",
            key: "hotWaterPrice2",
            filters: [
              {
                key: "hotWater",
                value: "3"
              }
              // {
              //   key: "rentalStyle",
              //   value: "0"
              // }
            ],
            isCol50: true,
            rules: [{ required: true }],
            opt: {
              keyupNative: true
            }
          }
        ]
      },
      shortOpt: {
        form: [
          {
            label: "是否打折",
            key: "shortDiscountFlags",
            value: "0",
            only: "other",
            type: "select",
            opt: {
              list: [
                {
                  label: "否",
                  value: "0"
                },
                {
                  label: "是",
                  value: "1"
                }
              ],
              change(val) {
                _this.isShortDiscount = val == "1";
              }
            },
            isCol33: true,
            rules: [{ required: true }]
          },
          {
            label: "打折时段",
            key: "shortDiscountType",
            type: "select",
            filters: [
              {
                key: "shortDiscountFlags",
                value: "1"
              }
            ],
            value: "0",
            opt: {
              list: [
                {
                  label: "持续打折",
                  value: "0"
                },
                {
                  label: "自定义打折时段",
                  value: "1"
                },
                {
                  label: "按周打折",
                  value: "2"
                },
                {
                  label: "按月打折",
                  value: "3"
                }
              ]
            },
            isCol33: true,
            rules: [{ required: true }]
          },
          {
            label: "自定义打折时段",
            key: "shortDiscountDateRangeList",
            type: "datetimerange-list",
            filters: [
              {
                key: "shortDiscountFlags",
                value: "1"
              },
              {
                key: "shortDiscountType",
                value: "1"
              }
            ],
            opt: {
              limit: [1, "years"],
              start: new Date(),
              length: 10
            },
            rules: [{ required: true }]
          },
          {
            label: "按周打折",
            key: "shortDiscountWeeklyRangeList",
            type: "weekly-range",
            filters: [
              {
                key: "shortDiscountFlags",
                value: "1"
              },
              {
                key: "shortDiscountType",
                value: "2"
              }
            ],
            opt: {
              alt: "选择按周打折时间。如开启周一打折，每周一房间打折"
            },
            rules: [{ required: true }]
          },
          {
            label: "按月打折",
            key: "shortDiscountMonthlyRangeList",
            type: "monthly-range",
            filters: [
              {
                key: "shortDiscountFlags",
                value: "1"
              },
              {
                key: "shortDiscountType",
                value: "3"
              }
            ],
            opt: {
              alt: "选择按月打折时间。如选择5日，每月5号房间打折"
            },
            rules: [{ required: true }]
          }
        ]
      },
      roomList: [],
      roomTemplate: {
        roomNo: "",
        // rentalDiscountFlag: "0", //长租是否打折，0不打折，1打折
        rentalOriginalFee: "", //长租原价
        rentalFee: "", //长租折后价
        deposit: "", //长租押金
        propertyFee: "", //物业费,
        // shortDiscountFlag: "0", //短租是否打折，0不打折，1打折
        shortOriginalFee: "", //短租原价
        shortRentFee: "", //短租折后价
        shortDeposit: "" //短租押金
      },
      roomTemplateCache: null,
      isShowLong: true,
      isShowShort: false,
      isShowClock: false,
      isLongDiscount: false,
      isShortDiscount: false,
      roomNumLong: "",
      apartmentList:[],
      buildingsList:[]
    };
  },
  created: function() {
    console.log("room-batch created!!");
  },
  activated() {
    this.getApartmentList();
    this.initBatchList();
  },
  methods: {
    keyUpChange(index, label) {
      let _this = this;
      _this.roomList[index][label] = this.clearNoNum(
        _this.roomList[index][label].toString()
      );
    },
    //控制只能输入数字和小数点后2位
    clearNoNum(value) {
      value = value.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      value = value
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".");
      value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数

      if (value.indexOf(".") < 0 && value != "") {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        value = parseFloat(value);
      }
      return value;
    },
    //初始化批量列表
    initBatchList() {
      this.isLongDiscount = false;
      this.isShortDiscount = false;
      this.roomNumLong = "5";
      let _list = [];
      for (let i = 0; i < 5; i++) {
        _list.push(JSON.parse(JSON.stringify(this.roomTemplate)));
      }
      this.roomList = _list;
    },
    //获取房源列表
    getApartmentList() {
      var dto = {
        pageNumber: "1",
        pageSize: "99999",
        landlordId:
          this.cache.getLS("userInfo")["roleType"] == "1"
            ? null
            : this.cache.getLS("userInfo")["userId"]
      };
      this.post("landlord-service/apartment/queryPagesApartment", dto).then(
        res => {
          res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
          res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
          this.baseOpt.form[0].opt.list = res;
          this.apartmentList = res
        }
      );
    },
    // 获取房型
    getTypeIdList(apartmentId) {
      this.post("landlord-service/base/getRoomTypeAll", {}).then(res => {
        let baseType = {};
        res.forEach(item => {
          baseType[item.id] = item.name;
        });
        this.post("landlord-service/roomType/queryPagesRoomType", {
          pageSize: "99999",
          pageNumber: "1",
          landlordId:
            this.cache.getLS("userInfo")["roleType"] == "1"
              ? null
              : this.cache.getLS("userInfo")["userId"],
          apartmentId: apartmentId
        }).then(resData => {
          resData.forEach(item => {
            item.label = baseType[item.typeId];
            item.value = item.typeId;
          });
          this.baseOpt.form[1].opt.list = resData;
        });
      });
    },
    //增加行
    addItem(idx) {
      //   console.log(idx);
      if (this.roomList.length == 20) {
        this.$message({
          showClose: true,
          message: "最多添加至20项",
          type: "warning"
        });
      } else {
        this.roomList.splice(
          idx + 1,
          0,
          JSON.parse(JSON.stringify(this.roomTemplate))
        );
        this.roomNumLong = this.roomList.length;
      }
    },
    //删除行
    delItem(idx) {
      //   console.log(idx);

      if (this.roomList.length == 1) {
        this.$message({
          showClose: true,
          message: "至少保留1项",
          type: "warning"
        });
      } else {
        this.roomNumLong = this.roomList.length - 1;
        this.roomList.splice(idx, 1);
      }
    },
    /***
     * 提交
     */
    onSubmit() {
      let base = new Promise((resolve, reject) => {
        this.$tel.call("roomDatchBaseForm", (refForm, formData) => {
          refForm.validate(valid => {
            if (valid) {
              resolve(formData);
            } else {
              reject();
            }
          });
        });
      });
      let long = new Promise((resolve, reject) => {
        this.$tel.call("roomDatchLongForm", (refForm, formData) => {
          refForm.validate(valid => {
            if (valid) {
              resolve(formData);
            } else {
              reject();
            }
          });
        });
      });
      let short = new Promise((resolve, reject) => {
        this.$tel.call("roomDatchShortForm", (refForm, formData) => {
          refForm.validate(valid => {
            if (valid) {
              resolve(formData);
            } else {
              reject();
            }
          });
        });
      });

      base.then(baseData => {
          if (baseData.roomPics.length > 0) {
            let imgList = [];
            for (let i = 0; i < baseData.roomPics.length; i++) {
              imgList.push(baseData.roomPics[i].url);
            }
            baseData.roomPicList = imgList;
          } else {
            baseData.roomPicList = [];
          }

          let _promiseList = [];
          switch (baseData.rentalStyle) {
            case "0":
              _promiseList = [long];
              break;
            case "1":
              _promiseList = [short];
              break;
            case "2":
              _promiseList = [long, short];
              break;
            case "3":
              _promiseList = [];
              break;
            default:
              break;
          }

          Promise.all(_promiseList)
            .then(values => {
              switch (baseData.rentalStyle) {
                case "0":
                  values = [baseData].concat([values[0], {}]);
                  break;
                case "1":
                  values = [baseData].concat([{}, values[0]]);
                  break;
                case "2":
                  values = [baseData].concat(values);
                  break;
                case "3":
                  values = [baseData];
                  break;
                default:
                  break;
              }
              send(values, this.roomList);
            })
            .catch(e => {
              console.error(e);
              this.$message({
                showClose: true,
                message: "请输入需要填写的信息",
                type: "warning"
              });
            });
        })
        .catch(e => {
          console.error(e);
          this.$message({
            showClose: true,
            message: "请输入需要填写的信息",
            type: "warning"
          });
        });

      let _this = this;
      function send(values, roomList) {
        let dto = [];
        for (let i = 0; i < roomList.length; i++) {
          let room = JSON.parse(JSON.stringify(roomList[i]));
          if (values[0].rentalStyle == "0") {
            delete room.shortDiscountFlag;
            delete room.shortOriginalFee;
            delete room.shortRentFee;
            delete room.shortDeposit;
          }
          if (values[0].rentalStyle == "1") {
            delete room.rentalDiscountFlag;
            delete room.rentalOriginalFee;
            delete room.rentalFee;
            delete room.deposit;
            delete room.propertyFee;
          }
          if (values[0].rentalStyle == "3") {
            delete room.rentalDiscountFlag;
            delete room.rentalOriginalFee;
            delete room.propertyFee;
            delete room.shortDiscountFlag;
            delete room.shortOriginalFee;
            delete room.shortRentFee;
            delete room.shortDeposit;
          }
          if (values[0].rentalStyle != "1" && values[0].rentalStyle !== "3") {
            if (values[1].electricityFeesPrice) {
              values[1].electricityFeesPrice = parseFloat(
                values[1].electricityFeesPrice
              ).toFixed(2);
            }
            if (values[1].coldWaterPrice) {
              values[1].coldWaterPrice = parseFloat(
                values[1].coldWaterPrice
              ).toFixed(2);
            }
            if (values[1].coldWaterPrice2) {
              values[1].coldWaterPrice2 = parseFloat(
                values[1].coldWaterPrice2
              ).toFixed(2);
            }
            if (values[1].hotWaterPrice) {
              values[1].hotWaterPrice = parseFloat(
                values[1].hotWaterPrice
              ).toFixed(2);
            }
            if (values[1].hotWaterPrice2) {
              values[1].hotWaterPrice2 = parseFloat(
                values[1].hotWaterPrice2
              ).toFixed(2);
            }
            if ( JSON.stringify(values[1].chargingModelData) != "{}" && values[1].chargingModelData ) {
              room.chargingModel = {
                1: {
                  id: values[1].electricityFeesId
                    ? values[1].electricityFeesId
                    : "",
                  roomId: values[1].id ? values[1].id : "",
                  model: values[1].electricityFees,
                  unitPrice: values[1].electricityFeesPrice,
                  waterElectricId:
                    chargingModelData &&
                    chargingModelData["1"] &&
                    chargingModelData["1"].waterElectricId
                      ? chargingModelData["1"].waterElectricId
                      : ""
                },
                2: {
                  id: values[1].coldWaterId ? values[1].coldWaterId : "",
                  roomId: values[1].id ? values[1].id : "",
                  model: values[1].coldWater,
                  unitPrice:
                    values[1].coldWater == 2
                      ? values[1].coldWaterPrice
                      : values[1].coldWaterPrice2,
                  waterElectricId:
                    chargingModelData &&
                    chargingModelData["2"] &&
                    chargingModelData["2"].waterElectricId
                      ? chargingModelData["2"].waterElectricId
                      : ""
                },
                3: {
                  id: values[1].hotWaterId ? values[1].hotWaterId : "",
                  roomId: values[1].id ? values[1].id : "",
                  model: values[1].hotWater,
                  unitPrice:
                    values[1].hotWater == 2
                      ? values[1].hotWaterPrice
                      : values[1].hotWaterPrice2,
                  waterElectricId:
                    chargingModelData &&
                    chargingModelData["3"] &&
                    chargingModelData["3"].waterElectricId
                      ? chargingModelData["3"].waterElectricId
                      : ""
                }
              };
            } else {
              room.chargingModel = {
                1: {
                  id: values[1].electricityFeesId
                    ? values[1].electricityFeesId
                    : "",
                  roomId: values[1].id ? values[1].id : "",
                  model: values[1].electricityFees,
                  unitPrice: values[1].electricityFeesPrice,
                  waterElectricId: ""
                },
                2: {
                  id: values[1].coldWaterId ? values[1].coldWaterId : "",
                  roomId: values[1].id ? values[1].id : "",
                  model: values[1].coldWater,
                  unitPrice:
                    values[1].coldWater == 2
                      ? values[1].coldWaterPrice
                      : values[1].coldWaterPrice2,
                  waterElectricId: ""
                },
                3: {
                  id: values[1].hotWaterId ? values[1].hotWaterId : "",
                  roomId: values[1].id ? values[1].id : "",
                  model: values[1].hotWater,
                  unitPrice:
                    values[1].hotWater == 2
                      ? values[1].hotWaterPrice
                      : values[1].hotWaterPrice2,
                  waterElectricId: ""
                }
              };
            }
          }
          if (i == 0) {
            // let reg = /^[a-zA-Z0-9]{1,}$/;
            // let reg = /^[\u4E00-\u9FA5a-zA-Z0-9,_,-]{1,250}$/;
            let reg = /^[a-zA-Z0-9\u4e00-\u9fa5]{1,24}$/;
            if (!room.roomNo) {
              return _this.$message({
                showClose: true,
                message: "序号为" + (i + 1) + "的房间必须填写房号",
                type: "warning"
              });
            }
            if (!reg.test(room.roomNo)) {
              return _this.$message({
                showClose: true,
                message: "序号为" + (i + 1) + "的房间必须房间号支持输入数字、汉字，大小写字母，限制字符长度1~24个字符",
                type: "warning"
              });
            }
            if (values[0].rentalStyle != "1" && values[0].rentalStyle != "3") {
              //长租打折判断
               room.rentalFee=room.rentalFee+'';
               room.rentalOriginalFee=room.rentalOriginalFee+'';
               room.deposit=room.deposit+'';
               room.propertyFee=room.propertyFee+'';
               values[1].rentalDiscountFlag = "0";//注释是否打折
              if (values[1].rentalDiscountFlag == "0") {
                if (!room.rentalFee) {
                  return _this.$message({
                    showClose: true,
                    message: "序号为" + (i + 1) + "的房间必须填写房价(长租)",
                    type: "warning"
                  });
                } else {
                  if (room.rentalFee.search(_this.$pattern.point09.val) != -1) {
                    return _this.$message({
                      showClose: true,
                      message:
                        "序号为" + (i + 1) + "的房间房价必须是具体的数值(长租)",
                      type: "warning"
                    });
                  }
                }
              } else {
                if (!room.rentalOriginalFee || !room.rentalFee) {
                  return _this.$message({
                    showClose: true,
                    message:
                      "序号为" +
                      (i + 1) +
                      "的房间打折必须填写原价与折后价(长租)",
                    type: "warning"
                  });
                } else if (
                  room.rentalOriginalFee.search(_this.$pattern.point09.val) !=
                    -1 ||
                  room.rentalFee.search(_this.$pattern.point09.val) != -1
                ) {
                  return _this.$message({
                    showClose: true,
                    message:
                      "序号为" +
                      (i + 1) +
                      "的房间原价与折后价必须是具体的数值(长租)",
                    type: "warning"
                  });
                } else if (
                  Number(room.rentalOriginalFee) <= Number(room.rentalFee)
                ) {
                  return _this.$message({
                    showClose: true,
                    message:
                      "序号为" + (i + 1) + "的房间折后价必须小于原价(长租)",
                    type: "warning"
                  });
                }
                if (values[1].rentalDiscountType == 1) {
                  values[1].rentalDiscountStartDate = moment(
                    values[1].rentalDiscountTimeRange[0]
                  ).format("YYYY-MM-DD HH:mm:ss");
                  values[1].rentalDiscountEndDate = moment(
                    values[1].rentalDiscountTimeRange[1]
                  ).format("YYYY-MM-DD HH:mm:ss");
                  values[1].rentalDiscountFlag = "2";
                }
                // values[1].rentalDiscountFee = form.rentalFee;
                // values[1].rentalFee = form.rentalOriginalFee;
              }
              if (!room.deposit || !room.propertyFee) {
                return _this.$message({
                  showClose: true,
                  message:
                    "序号为" + (i + 1) + "的房间必须填写押金与物业费(长租)",
                  type: "warning"
                });
              } else if (
                room.deposit.search(_this.$pattern.point09.val) != -1 ||
                room.propertyFee.search(_this.$pattern.point09.val) != -1
              ) {
                return _this.$message({
                  showClose: true,
                  message:
                    "序号为" +
                    (i + 1) +
                    "的房间押金与物业费必须是具体的数值(长租)",
                  type: "warning"
                });
              }
            }
            if (values[0].rentalStyle != "0" && values[0].rentalStyle != "3") {
              //短租打折判断
              room.shortRentFee=room.shortRentFee+'';
              room.shortOriginalFee=room.shortOriginalFee+'';
              room.shortDeposit=room.shortDeposit+'';
              values[2].shortDiscountFlags = "0";//注释是否打折
              if (values[2].shortDiscountFlags == "0") {
                if (!room.shortRentFee) {
                  return _this.$message({
                    showClose: true,
                    message: "序号为" + (i + 1) + "的房间必须填写房价(短租)",
                    type: "warning"
                  });
                } else if (
                  room.shortRentFee.search(_this.$pattern.point09.val) != -1
                ) {
                  return _this.$message({
                    showClose: true,
                    message:
                      "序号为" + (i + 1) + "的房间房价必须是具体的数值(短租)",
                    type: "warning"
                  });
                }
              } else {
                switch (values[2].shortDiscountType) {
                  case "1":
                    let _duration = [];
                    var array = JSON.parse(
                      JSON.stringify(values[2].shortDiscountDateRangeList)
                    );
                    let lessNum = 0;
                    for (let i = 0; i < array.length; i++) {
                      console.log(JSON.stringify(array[i]));
                      if (JSON.stringify(array[i]) == "{}") {
                        lessNum++;
                      }
                    }
                    if (lessNum == array.length) {
                      return _this.$message({
                        showClose: true,
                        message: "请输入自定义打折时段",
                        type: "warning"
                      });
                    } else {
                      for (let i = 0; i < array.length; i++) {
                        if (JSON.stringify(array[i]) != "{}") {
                          let item = array[i];
                          _duration.push([
                            _this.format(item[0], 1),
                            _this.format(item[1], 1)
                          ]);
                        }
                      }
                    }
                    values[2].shortDiscountFlag = "4";
                    values[2].shortDiscountDetail = JSON.stringify({
                      duration: _duration
                    });
                    break;
                  case "2":
                    let _weekly = [];
                    for (
                      let i = 0;
                      i < values[2].shortDiscountWeeklyRangeList.length;
                      i++
                    ) {
                      let item = values[2].shortDiscountWeeklyRangeList[i];
                      _weekly.push(Number(item) + 1);
                    }
                    values[2].shortDiscountFlag = "6";
                    values[2].shortDiscountDetail = JSON.stringify({
                      weekly: _weekly
                    });
                    break;
                  case "3":
                    values[2].shortDiscountFlag = "5";
                    values[2].shortDiscountDetail = JSON.stringify({
                      monthly: values[2].shortDiscountMonthlyRangeList
                    });
                    break;
                  default:
                    break;
                }

                if (!room.shortOriginalFee || !room.shortRentFee) {
                  return _this.$message({
                    showClose: true,
                    message:
                      "序号为" +
                      (i + 1) +
                      "的房间打折必须填写原价与折后价(短租)",
                    type: "warning"
                  });
                } else if (
                  room.shortOriginalFee.search(_this.$pattern.point09.val) !=
                    -1 ||
                  room.shortRentFee.search(_this.$pattern.point09.val) != -1
                ) {
                  return _this.$message({
                    showClose: true,
                    message:
                      "序号为" +
                      (i + 1) +
                      "的房间原价与折后价必须是具体的数值(短租)",
                    type: "warning"
                  });
                } else if (
                  Number(room.shortOriginalFee) <= Number(room.shortRentFee)
                ) {
                  return _this.$message({
                    showClose: true,
                    message:
                      "序号为" + (i + 1) + "的房间折后价必须小于原价(短租)",
                    type: "warning"
                  });
                }
              }
              if (!room.shortDeposit) {
                return _this.$message({
                  showClose: true,
                  message: "序号为" + (i + 1) + "的房间必须填写押金(短租)",
                  type: "warning"
                });
              } else if (
                room.shortDeposit.search(_this.$pattern.point09.val) != -1
              ) {
                return _this.$message({
                  showClose: true,
                  message:
                    "序号为" + (i + 1) + "的房间押金必须是具体的数值(长租)",
                  type: "warning"
                });
              }
            }
            _this.roomTemplateCache = JSON.parse(JSON.stringify(room));
          }

          if (i != 0) {
            if (values[0].rentalStyle == "0" || values[0].rentalStyle == "2") {
              if (!room.rentalOriginalFee) {
                room.rentalOriginalFee =
                  _this.roomTemplateCache.rentalOriginalFee;
              } else {
                _this.roomTemplateCache.rentalOriginalFee = JSON.parse(
                  JSON.stringify(room.rentalOriginalFee)
                );
              }
              if (!room.rentalFee) {
                room.rentalFee = _this.roomTemplateCache.rentalFee;
              } else {
                _this.roomTemplateCache.rentalFee = JSON.parse(
                  JSON.stringify(room.rentalFee)
                );
              }
              if (!room.deposit) {
                room.deposit = _this.roomTemplateCache.deposit;
              } else {
                _this.roomTemplateCache.deposit = JSON.parse(
                  JSON.stringify(room.deposit)
                );
              }
              if (!room.propertyFee) {
                room.propertyFee = _this.roomTemplateCache.propertyFee;
              } else {
                _this.roomTemplateCache.propertyFee = JSON.parse(
                  JSON.stringify(room.propertyFee)
                );
              }
              if (!room.rentalDiscountFee) {
                room.rentalDiscountFee =
                  _this.roomTemplateCache.rentalDiscountFee;
              } else {
                _this.roomTemplateCache.rentalDiscountFee = JSON.parse(
                  JSON.stringify(room.rentalDiscountFee)
                );
              }
            }

            if (values[0].rentalStyle == "3") {
              if (!room.rentalFee) {
                room.rentalFee = _this.roomTemplateCache.rentalFee;
              } else {
                _this.roomTemplateCache.rentalFee = JSON.parse(
                  JSON.stringify(room.rentalFee)
                );
              }
              if (!room.deposit) {
                room.deposit = _this.roomTemplateCache.deposit;
              } else {
                _this.roomTemplateCache.deposit = JSON.parse(
                  JSON.stringify(room.deposit)
                );
              }
            }

            if (values[0].rentalStyle == "1" || values[0].rentalStyle == "2") {
              if (!room.shortOriginalFee) {
                room.shortOriginalFee =
                  _this.roomTemplateCache.shortOriginalFee;
              } else {
                _this.roomTemplateCache.shortOriginalFee = JSON.parse(
                  JSON.stringify(room.shortOriginalFee)
                );
              }
              if (!room.shortRentFee) {
                room.shortRentFee = _this.roomTemplateCache.shortRentFee;
              } else {
                _this.roomTemplateCache.shortRentFee = JSON.parse(
                  JSON.stringify(room.shortRentFee)
                );
              }
              if (!room.shortDeposit) {
                room.shortDeposit = _this.roomTemplateCache.shortDeposit;
              } else {
                _this.roomTemplateCache.shortDeposit = JSON.parse(
                  JSON.stringify(room.shortDeposit)
                );
              }
              if (!room.shortDiscountFee) {
                room.shortDiscountFee =
                  _this.roomTemplateCache.shortDiscountFee;
              } else {
                _this.roomTemplateCache.shortDiscountFee = JSON.parse(
                  JSON.stringify(room.shortDiscountFee)
                );
              }
            }
          }
          let obj = {
            ...values[0],
            ...values[1],
            ...values[2],
            ...room
          };
          if (obj.rentalDiscountFlag > 0) {
            obj.rentalDiscountFee = obj.rentalFee;
            obj.rentalFee = obj.rentalOriginalFee;
          }
          if (obj.shortDiscountFlag > 0) {
            obj.shortDiscountFee = obj.shortRentFee;
            obj.shortRentFee = obj.shortOriginalFee;
          }
          dto.push(obj);
        }
        let dtoDatas = [];
        for (let i = 0; i < dto.length; i++) {
          _this.apartmentList.forEach(item =>{
            if(dto[i].apartmentId == item.value){
              dto[i].apartmentName = item.label
            }
          })
          _this.buildingsList.forEach(item =>{
            if(dto[i].buildingNo == item.value){
              dto[i].buildName = item.label
            }
          })
          dto[i].roomInfo = dto[i].apartmentName+dto[i].buildName+dto[i].roomNo
          if (dto[i].roomNo != "") {
            dtoDatas.push(dto[i]);
          }
        }

        _this
          .post("/landlord-service/room/createRoomBatch", dtoDatas)
          .then(function() {
            _this.$message({
              showClose: true,
              message: "批量创建房间成功",
              type: "success"
            });
            _this.$back();
          });
      }
    },
    longFun(e) {
      console.log(e);
      if (e > 100) {
        this.$message({
          showClose: true,
          message: "最多添加至100项",
          type: "warning"
        });
      } else {
        if (e < 0) {
          e = 1;
          this.roomNumLong = 1;
        }
        let _listLong = [];
        for (let i = 0; i < e; i++) {
          _listLong.push(JSON.parse(JSON.stringify(this.roomTemplate)));
        }
        this.roomList = _listLong;
      }
    }
  }
};
</script>
<style lang="scss">
.room-batch {
  .el-form-item__label {
    flex: none !important;
    width: 150px;
  }
  .row-title {
    margin: 0 auto;
    padding-bottom: 6px;
    margin-top: 10px;
    margin-bottom: 15px;
    max-width: 1200px;
    color: #606266;
    font-size: 16px;
    border-bottom: solid 1px #e4e7ed;
  }

  .row-line {
    margin: 0 auto;
    margin-bottom: 15px;
    max-width: 1200px;
    border-bottom: solid 1px #e4e7ed;
  }

  .list-box {
    margin: 0 auto;
    max-width: 1200px;
  }

  .list-item {
    padding: 4px 0;
    margin-bottom: 6px;
    background-color: #eee;
    .list-idx {
      display: inline-block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 14px;
      color: #606266;
    }
    .list-col {
      display: inline-block;
      label {
        margin-left: 10px;
        margin-right: 6px;
        font-size: 14px;
        color: #606266;
        line-height: 40px;
      }
      .el-input {
        width: 120px;
      }
    }
    .list-cols {
      display: inline-block;
      label {
        margin-left: 10px;
        margin-right: 6px;
        font-size: 14px;
        color: #606266;
        line-height: 40px;
      }
      .el-input {
        width: 180px;
      }
      span {
        font-size: 14px;
        color: red;
        margin-left: 20px;
      }
    }
    .list-buts {
      float: right;
      padding-right: 10px;
    }
  }

  .action-but {
    padding-left: 0;
    margin-top: 50px;
    margin-bottom: 30px;
  }
}
.app-form .el-form .item-box-33 .el-form-item__label {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 150px;
}
</style>
